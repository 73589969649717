import request from '@/utils/request'

export function putRoleObj(data) {
  return request({
    url: '/admin/role',
    method: 'put',
    data
  })
}

export function delRoleObj(id) {
  return request({
    url: `/admin/role/${id}`,
    method: 'delete',
  })
}

export function fetchRoleList(data) {
  return request({
    url: '/admin/role/page',
    method: 'get',
    params: data
  })
}

export function addRoleObj(data) {
  return request({
    url: '/admin/role',
    method: 'post',
    data
  })
}

/**
 * 更新角色权限
 * @param {*} data 
 * @returns 
 */
export function updateRolePermissions(data) {
  return request({
    url: '/admin/role/menu',
    method: 'put',
    data
  })
}

