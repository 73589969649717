<template>
  <div class="user-container">
    <Jump :jumpList="jumpList" />
    <NavMenu :activeUrl="activeUrl" />
    <div class="page-wrap">
      <chil-menu />
      <div class="user-wrap">
        <div class="user-tree">
          <avue-tree
                  :option="treeOption"
                  :data="treeData"
                  @node-click="nodeClick"
          >
          <span class="el-tree-node__label" slot-scope="{ node, data }">
            <span>{{ node.label }}</span>
          </span>
          </avue-tree>
        </div>
        <div class="user-main">
          <avue-crud
                  ref="crud"
                  :option="option"
                  v-model="form"
                  :page.sync="page"
                  :table-loading="listLoading"
                  :before-open="handleOpenBefore"
                  :data="list"
                  @on-load="getList"
                  @search-change="searchChange"
                  @refresh-change="refreshChange"
                  @size-change="sizeChange"
                  @current-change="currentChange"
                  @row-update="update"
                  @row-save="create"
                  stripe
          >
            <!-- <template slot-scope="{}" slot="usernameLabel">
              <div style="text-align:right">用户名</div>
            </template>
            <template slot-scope="{}" slot="passwordLabel">
              <div style="text-align: right">密码：</div>
            </template> -->
            <template slot="menuLeft">
              <el-button
                      class="filter-item"
                      type="primary"
                      size="small"
                      icon="el-icon-plus"
                      @click="$refs.crud.rowAdd()"
                      v-auth="['admin_user_add']"
              >新增
              </el-button>
              <el-button
                      class="filter-item"
                      type="primary"
                      size="small"
                      @click="$refs.uploadExcel.show()"
                      icon="el-icon-upload"
                      v-auth="['admin_user_add']"
              >导入
              </el-button>
            </template>
            <template slot="menuRight">
              <div style="display: flex">
                <div class="line-alignment">
                  <el-input
                          placeholder="请输入用户名"
                          v-model="search.username"
                  />
                  <el-button
                          class="filter-item"
                          type="primary"
                          size="small"
                          icon="el-icon-search"
                          style="margin-left: 40px"
                          @click="getList(page, search)"
                  >查询
                  </el-button>
                </div>
              </div>
            </template>
            <template slot="username" slot-scope="scope">
              <span>{{ scope.row.username }}</span>
            </template>
            <template slot="role" slot-scope="scope">
            <span v-for="(role, index) in scope.row.roleList" :key="index">
              <el-tag>{{ role.roleName }} </el-tag>&nbsp;&nbsp;
            </span>
            </template>
            <template slot="deptId" slot-scope="scope">
              {{ scope.row.deptName }}
            </template>
            <template slot="lockFlag" slot-scope="scope">
              <el-tag>{{ scope.label }}</el-tag>
            </template>
            <template slot="menu" slot-scope="scope">
              <el-button
                      type="text"
                      size="small"
                      icon="el-icon-edit"
                      @click="handleUpdate(scope.row, scope.index)"
                      v-auth="['admin_user_edit']"
              >编辑
              </el-button>
              <el-button
                      type="text"
                      size="small"
                      icon="el-icon-delete"
                      @click="deletes(scope.row, scope.index)"
                      v-auth="['admin_user_delete']"
              >删除
              </el-button>
            </template>
            <template slot="deptIdForm" slot-scope="scope">
              <avue-input-tree
                      v-model="form.deptId"
                      :node-click="getNodeData"
                      :dic="treeDeptData"
                      :props="defaultProps"
                      placeholder="请选择所属部门"
              />
            </template>
            <template slot="roleForm" slot-scope="scope">
              <avue-select
                      v-model="role"
                      :dic="rolesOptions"
                      :props="roleProps"
                      multiple
                      placeholder="请选择角色"
              />
            </template>
          </avue-crud>
        </div>
      </div>
    </div>
    <uploadExcel ref="uploadExcel" url="/api/admin/user/importUser" title="导入用户" tempUrl="/api/admin/sys-file/local/file/userInfo.xlsx" @flushed="()=>getList(page,search)" />
  </div>
</template>

<script>
import Jump from "@/components/jump";
import NavMenu from "../../setting/navMenu.vue";
import { tableOption } from "./tableOption";
import { fetchDeptTree } from "@/api/deviceManagement.js";
import { addUserObj, delUserObj, fetchUserList, putUserObj } from "@/api/user";
import { fetchRoleList } from "@/api/role";
import { encryption } from "@/utils/util.js";
import ChilMenu from "../../setting/chilMenu.vue";
import uploadExcel from '@/components/upload/excel'
export default {
  name: "user",
  data() {
    return {
      activeUrl: "/setting/system",
      searchForm: {},
      treeOption: {
        nodeKey: "id",
        addBtn: false,
        menu: false,
        props: {
          label: "name",
          value: "id",
        },
      },
      treeData: [],
      option: tableOption,
      treeDeptData: [],
      checkedKeys: [],
      roleProps: {
        label: "roleName",
        value: "roleId",
      },
      defaultProps: {
        label: "name",
        value: "id",
      },
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 20, // 每页显示多少条,
        isAsc: false, // 是否倒序
      },
      search: {},
      list: [],
      listLoading: true,
      role: [],
      form: {},
      rolesOptions: [],
      deptId: null,
      jumpList: [
        { name: "首页", },
        { name: "系统管理",},
        { name: "用户管理",},
      ],
    };
  },
  components: {
    ChilMenu,
    NavMenu,
    Jump,
    uploadExcel
  },
  watch: {
    role() {
      this.form.role = this.role;
    },
  },
  created() {
    this.init();
  },
  methods: {
    tabRowClassName({ row, rowIndex }) {
      let index = rowIndex + 1;
      if (index % 2 == 0) {
        return "double-row";
      } else {
        return "single-row";
      }
    },
    init() {
      fetchDeptTree().then((response) => {
        this.treeData = response.data;
      });
    },
    nodeClick(data) {
      this.deptId = data.id;
      this.page.page = 1;
      this.getList(this.page, { deptId: data.id });
    },
    getList(page, params) {
      this.listLoading = true;
      const data = {
        current: page.currentPage,
        size: page.pageSize,
        ...params,
        ...this.searchForm,
      };
      fetchUserList(data).then((response) => {
        this.list = response.data.records;
        this.page.total = response.data.total;
        this.listLoading = false;
      });
    },
    getNodeData(item) {
      this.form.deptName = item.name
      fetchRoleList().then((response) => {
        this.rolesOptions = response.data.records;
      });
    },
    searchChange(param, done) {
      this.searchForm = param;
      this.page.currentPage = 1;
      this.getList(this.page, param);
      done();
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    currentChange(current) {
      this.page.currentPage = current;
    },
    refreshChange() {
      this.getList(this.page);
    },
    handleOpenBefore(show, type) {
      window.boxType = type;
      // 查询部门树
      fetchDeptTree().then((response) => {
        this.treeDeptData = response.data;
      });
      // 查询角色列表
      fetchRoleList({current:1,size:99}).then((response) => {
        this.rolesOptions = response.data.records;
      });

      if (["edit", "views"].includes(type)) {
        this.role = [];
        for (let i = 0; i < this.form.roleList.length; i++) {
          this.role[i] = this.form.roleList[i].roleId;
        }
      } else if (type === "add") {
        console.log(this.deptId);
        this.role = [];
        this.form.deptId = this.deptId;
      }
      show();
    },
    handleUpdate(row, index) {
      this.$refs.crud.rowEdit(row, index);
      this.form.password = undefined;
    },
    create(row, done, loading) {
      if (this.form.phone.indexOf("*") > 0) {
        this.form.phone = undefined;
      }
      const form = encryption({
        data: this.form,
        key: "wertwertwertwert",
        param: ["password"],
      });
      addUserObj(form)
        .then((res) => {
          console.log(res.code);
          if (res.code === 1) {
            this.$notify.error(res.msg);
            done();
            return;
          }
          this.getList(this.page, { deptId: this.deptId });
          done();
          this.$notify.success("创建成功");
        })
        .catch(() => {
          loading();
        });
    },
    update(row, index, done, loading) {
      if (this.form.phone && this.form.phone.indexOf("*") > 0) {
        this.form.phone = undefined;
      }
      const form = encryption({
        data: this.form,
        key: "wertwertwertwert",
        param: ["password"],
      });
      if (!form.password) {
        delete form.password;
      }
      putUserObj(form)
        .then(() => {
          this.getList(this.page, { deptId: this.deptId });
          done();
          this.$notify.success("修改成功");
        })
        .catch(() => {
          loading();
        });
    },
    deletes(row, index) {
      this.$confirm(
        "此操作将永久删除该用户(用户名:" + row.username + "), 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        delUserObj(row.userId)
          .then(() => {
            this.list.splice(index, 1);
            this.$notify.success("删除成功");
          })
          .catch(() => {
            this.$notify.error("删除失败");
          });
      });
    },
  },
};
</script>

<style lang="scss">
.avue-dialog .el-dialog {
  width: 35% !important;
}
.page-wrap {
  display: flex;
  .user-container {
    background: transparent;
    box-sizing: border-box;
    padding: 0 20px;
    flex: 1;
    width: 0;
  }
}
.user-container {
  padding: 0 10px;
  .user-wrap {
    display: flex;
    margin-left: 10px;
    .user-tree {
      width: 230px;
      flex-shrink: 0;
      padding-top: 20px;
    }
  }
  .user {
    height: 100%;

    &__tree {
      padding-top: 3px;
      padding-right: 20px;
    }

    &__main {
      .el-card__body {
        padding-top: 0;
      }
    }
  }
  .el-tree {
    padding-top: 15px;
    height: calc(100vh - 300px);
    background: #0c265a;
  }
  .el-card {
    background-color: transparent;
    border: none;
  }
  .avue-crud {
    background: transparent;
  }
  .avue-crud__menu {
    background: transparent;
  }
  ::v-deep .single-row {
    font-family: "MicrosoftYaHei";
  }
  ::v-deep .double-row {
    background: #0c265a !important;
    color: #ffffff;
    font-size: 14px;
    font-family: "MicrosoftYaHei";
    font-weight: 400;
  }
  .avue-crud .el-table th {
    background: rgb(17, 50, 117);
    color: rgb(163, 214, 255);
    border: none;
  }
  .el-table tr {
    color: #ffffff;
    background-color: #081e49;
  }
  .el-table--striped
    .el-table__body
    tr.el-table__row--striped
    td.el-table__cell {
    background-color: #0c265a;
    border-bottom: 1px solid #1d3b6a;
    border-top: 1px solid #1d3b6a;
  }
  .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-bottom: 0px;
  }
  .el-table th.el-table__cell {
    color: #a3d6ff;
    background-color: #113275;
  }
  .el-table--border::after,
  .el-table--group::after,
  .el-table::before,
  .el-table__fixed-right::before,
  .el-table__fixed::before {
    background-color: #1d3b6a;
  }
  .el-table--border,
  .el-table--group {
    border: none;
    margin-top: 20px;
  }
  .el-table__body tr.hover-row.current-row > td.el-table__cell,
  .el-table__body
    tr.hover-row.el-table__row--striped.current-row
    > td.el-table__cell,
  .el-table__body tr.hover-row.el-table__row--striped > td.el-table__cell,
  .el-table__body tr.hover-row > td.el-table__cell {
    background-color: #113275 !important;
  }
  .el-table--border .el-table__cell,
  .el-table__body-wrapper
    .el-table--border.is-scrolling-left
    ~ .el-table__fixed {
    border-right: none;
  }
  .el-pagination__total {
    color: #a3d6ff;
  }
  .el-tag {
    border-color: #1d3b6a;
    background-color: transparent;
  }
  .avue-crud {
    width: 95%;
  }

  // button {
  //   height: 36px;
  //   background: linear-gradient(0deg, #1f79ff, #23b4ff);
  //   border-radius: 2px;
  //   line-height: 10px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  // }
  .el-button--small.is-circle {
    display: none;
  }
  .el-card__body {
    padding-top: 20px !important;
  }
  .el-scrollbar__wrap {
    overflow: auto;
  }
  .el-radio.is-bordered {
    border: 1px solid rgba(73, 108, 180, 0.36) !important;
  }
  .el-radio {
    color: #a3d6ff;
  }

  // .el-select-dropdown__list{
  //   background: #06193C;
  // }
  .el-pagination__jump {
    color: #a3d6ff;
  }
  .el-table__empty-block {
    background: #0c265a;
  }
  .avue-empty__desc {
    color: #a3d6ff;
  }
  .avue-empty__image img {
    display: none;
  }
  .avue-empty__image {
    height: 0px !important;
  }
}
.el-select-dropdown__list {
  background: #06193c;
}
.el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
  background-color: #0c265a;
}
.avue-dialog .el-tag.el-tag--info {
  color: #a3d6ff;
  background: #0c265a;
  border: 1px solid rgba(73, 108, 180, 0.36) !important;
}
.avue-dialog .el-dialog__header {
  background: url("../../../assets/dialog_header_bk.png");
  border-bottom: none !important;
  .el-dialog__headerbtn {
    top: 20px !important;
  }
}
.avue-dialog .el-dialog__title {
  color: #a3d6ff !important;
  // padding-left: 45px;
  font-size: 18px;
  font-weight: bold !important;
}
.avue-dialog .el-dialog__body {
  background: #0c265a;
}
.avue-dialog .avue-dialog__footer {
  background: #0c265a;
  border: none !important;
}
.el-collapse-item__wrap {
  background: transparent;
}
.avue-group .el-collapse,
.avue-group .el-collapse-item__wrap {
  border-color: transparent;
}
.avue-form__group--flex {
  .el-form-item__label {
    color: #a3d6ff;
  }
  .el-form-item--small .el-form-item__content,
  .el-form-item--small .el-form-item__label {
    // display: flex;
  }
}
</style>
