<template>
  <div>
    <!-- 导入对话框 -->
    <el-dialog
      :title="title"
      :visible.sync="upload.open"
      width="400px"
      append-to-body
    >
      <el-upload
        ref="upload"
        :limit="1"
        accept=".xlsx, .xls"
        :headers="headers"
        :action="url"
        :disabled="upload.isUploading"
        :on-progress="handleFileUploadProgress"
        :on-success="handleFileSuccess"
        :on-error="uploadError"
        :auto-upload="false"
        :data="data"
        drag
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          将文件拖到此处，或{{dictId}}
          <em>点击上传</em>
        </div>
        <div class="el-upload__tip text-center" slot="tip">
          <span style="color: #FFF">仅允许导入xls、xlsx格式文件。</span>
          <el-link
            type="primary"
            :underline="false"
            style="font-size: 12px; vertical-align: baseline"
            @click="downExcelTemp"

            >下载模板
          </el-link>
        </div>

        <!-- <div class="el-upload__tip text-center" slot="tip">
          <span>上传导入模版，上传文件名必须跟下载文件名一致。</span>
          <el-link
            type="primary"
            :underline="false"
            style="font-size: 12px; vertical-align: baseline"
            @click="uploadExcelTemp"
            >上传模板
          </el-link>
        </div>-->
      </el-upload>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitFileForm">确 定</el-button>
        <el-button @click="upload.open = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {importUser,downBlobFile} from "../../api/user";
import { getToken } from '@/utils/auth'
export default {
  name: "ExcelUpload",
  components: {},
  props: {
    url: {
      type: String,
    },
    title: {
      type: String,
    },
    tempUrl: {
      type: String,
    },
    dictId: {
      type: String,
    },
  },
  data() {
    return {
      upload: {
        open: false,
        isUploading: false,
      },
      errorVisible: false,
      errorData: [],
      uploadVisible: false,
      fileList: [],
      data: {
        dictId: null,
      },
    };
  },
  computed: {
    headers: function () {
      const token = getToken()
      return {
        Authorization: "Bearer " + token,
      };
    },
  },
  methods: {
    async httpRequest(params) {
      var fileObj = params.file;
      console.log(fileObj);
      // FormData 对象
      var form = new FormData();
      // // 文件对象
      form.append("file", fileObj);
      const loading = this.$loading({
        lock: true,
        text: "文件上传中",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let res = await importUser(form)
      console.log(res);
      loading.close();
      this.$message.success("模版上传成功");
      this.uploadVisible = false;
      this.fileList = [];
    },
    uploadError(err){
      this.upload.isUploading = false;
     const res = JSON.parse(err.message)
      this.$message.error(res.msg)
    },
    handChange(file){
      console.log(file)
    },
    onRemove(file, fileList) {
      this.fileList = fileList;
    },
    beforeUpload(file) {
      /*if (file.size / 1024 > 2 * 1000) {
        this.$message.warning(`不支持大于2M文件`);
        return false;
      }

      if (file.name !== `${this.title}.xlsx`) {
        this.$message.warning(`上传文件名必须为${this.title}`);
        return false;
      }*/
      return true;
    },
    uploadExcelTemp() {
      this.uploadVisible = true;
    },
    downExcelTemp() {
      if(process.env.NODE_ENV==='development'){
        window.open('http://192.168.40.95'+this.tempUrl,'_blank');
      }else{
        window.open(window.location.origin + this.tempUrl,'_blank');
      }
    },
    handleFileUploadProgress() {
      this.upload.isUploading = true;
    },
    handleFileSuccess(response) {
      if(response.code===1){
        this.$refs.upload.clearFiles();
        return this.$message.error(response.msg)
      }
      this.$message.success(response.data)
      this.upload.isUploading = false;
      this.upload.open = false;
      this.$refs.upload.clearFiles();
      this.$emit('flushed')
    },
    submitFileForm() {
      this.data.dictId = this.dictId
      this.$refs.upload.submit();
    },
    show() {
      this.upload.isUploading = false;
      this.upload.open = true;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/views/avue-table.scss";
::v-deep .el-upload-dragger{
  background-color: #152559;
}
</style>
